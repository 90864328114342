globalThis["__sentryRewritesTunnelPath__"] = "/monitoring-tunnel";
globalThis["SENTRY_RELEASE"] = {"id":"60a0ec398d322dba5b0bf9edbbd1c373c12c893a"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: 'https://699d6044057685bc48c1e7d130ad0bce@o4507266158297088.ingest.de.sentry.io/4507270724714576',
  enabled: true,
  // enabled: process.env.NODE_ENV === 'production',
  // Replay may only be enabled for the client-side
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [/.*\.learnwise\..*/], // Anything for our domains
      networkRequestHeaders: ['X-Clerk-Auth-Status'],
      mask: ['cl-formFieldInput__password'],
    }),
    Sentry.httpClientIntegration(),
    Sentry.breadcrumbsIntegration({
      console: true, // Enable console integration to capture logs as breadcrumbs
    }),
    Sentry.browserTracingIntegration(),
  ],
  tracePropagationTargets: ['localhost', /^https:\/\/api\.chat.*/],

  tracesSampleRate: 1.0,
  // Capture Replay for 2% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.02,
  replaysOnErrorSampleRate: 1.0,

  // ...

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  attachStacktrace: true,
  normalizeDepth: 10,

  beforeSend: function (event, hint) {
    const exception: any = hint.originalException;

    if (exception.name === 'APIError') {
      event.fingerprint = [
        "{{ default }}",
        String(exception.name),
      ];
    }

    return event;
  },
})
